import React, { ReactElement, useEffect, useState } from 'react';
import Link from 'next/link';
import { useApi } from 'lib/contexts/ApplicationState';
import { Carousel } from 'components/Carousel';
import { DesktopLogo } from 'components/AppBar/DesktopLogo';
import { Post } from 'lib/types/posts';
import {
  Brush,
  Business,
  Facebook,
  Instagram,
  Laptop,
  PhotoCamera,
  SportsBasketballOutlined,
  Star,
  Theaters,
  Videocam,
  X,
  YouTube,
  Menu as MenuIcon,
} from '@mui/icons-material';
import { TikTokIcon } from 'elements/TikTokIcon';
import { ThreadsIcon } from 'elements/ThreadsIcon';
import { JobsModule } from 'features/Home/LoggedOutModules/JobsModule';
import { CreatorHighlights } from 'features/Home/LoggedOutModules/CreatorHighlights';
import { GondolaHead } from 'components/GondolaHead/GondolaHead';
import { Footer } from 'components/Footer';
import {
  Menu,
  MenuItem,
  IconButton,
  Divider,
} from '@mui/material';
import { useRouter } from 'next/router';
import { trackClickSignupEvent } from 'lib/hooks/useEventTracking';

const creativesUrl = 'https://on.gondola.cc/creatives';
const businessUrl = 'https://on.gondola.cc/business';

const LoggedOutHomeComponent = () => {
  const [trendingPosts, setTrendingPosts] = useState<Post[]>();
  const [hasFetchedTrendingPosts, setHasFetchedTrendingPosts] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const API = useApi();
  const router = useRouter();

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNavigation = (href: string) => {
    handleMenuClose();
    router.push(href);
  };

  useEffect(() => {
    const getTrendingPosts = async () => {
      try {
        setHasFetchedTrendingPosts(true);
        const posts = await API.getTrendingPosts();
        setTrendingPosts(posts);
      } catch (error) {
        console.error('Error fetching trending posts:', error);
      }
    };

    if (!hasFetchedTrendingPosts) {
      getTrendingPosts();
    }
  }, [API, hasFetchedTrendingPosts]);

  const testimonials = [
    {
      name: 'Avalon Koenig',
      companyTitle: 'Head of Production, Fresh Tape Media',
      quote: 'Everything I\'ve ever made is in one spot. I would never have the time to find and track my work without Gondola.',
      avatar: 'https://img.gondola.cc/tr:w-400,h-400,fo-auto/userAvatars/gondola/thatvideogal/7b5e181869.jpg',
    },
    {
      name: 'Billy Quach',
      companyTitle: 'SVP, Two Circles',
      quote: 'Gondola is the one-stop shop for tracking ALL your content across MULTIPLE platforms. It\'s that simple.',
      avatar: 'https://img.gondola.cc/tr:w-400,h-400,fo-auto/userAvatars/gondola/billyquachfilms/876cfc4ab8.jpg',
    },
    {
      name: 'Dontre Graves',
      companyTitle: 'Digital Media, 23XI Racing',
      quote: 'Something like this to manage all my content calls to me.',
      avatar: 'https://img.gondola.cc/tr:w-400,h-400,fo-auto/userAvatars/gondola/dontregraves/7294554aed.jpg',
    },
  ];

  // Note that the title is unique on the logged-out home page.
  // This helps us see it differently in Google Analytics.
  return (
    <>
      <GondolaHead
        title="Gondola: Showcase your work. Prove your impact. Unlock opportunities."
        description="Showcase your best work, track your content's impact, and get discovered for new opportunities. Gondola is the verified content attribution platform connecting creators, content, and career opportunities."
        url="https://gondola.cc"
      />

      <div className="min-h-screen">
        {/* Sticky Header */}
        <nav className="sticky top-0 bg-white border-b border-gray-100 z-50 h-18">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between items-center h-16">
              {/* Logo */}
              <div className="flex-shrink-0">
                <Link href="/" data-testid="logo" aria-label="gondola">
                  <div className="max-w-[120px] md:max-w-[120px]">
                    <DesktopLogo className="w-full" />
                  </div>
                </Link>
              </div>

              {/* Navigation Links - Desktop */}
              <div className="hidden md:flex items-center space-x-2 lg:space-x-8">
                <div className="relative group text-center ml-2">
                  <Link
                    href={creativesUrl}
                    className="text-gray-600 hover:text-alpineTeal py-2 text-sm uppercase font-bold underline-offset-4 hover:underline"
                  >
                    For Creatives
                  </Link>
                </div>
                <div className="relative group text-center">
                  <Link
                    href={businessUrl}
                    className="text-gray-600 hover:text-alpineTeal py-2 text-sm uppercase font-bold underline-offset-4 hover:underline"
                  >
                    For Businesses
                  </Link>
                </div>
                <div className="relative group text-center">
                  <Link href="/jobs" className="text-gray-600 text-center hover:text-alpineTeal px-3 py-2 text-sm font-bold uppercase underline-offset-4 hover:underline">
                    Find&nbsp;Jobs
                  </Link>
                </div>
                <div className="relative group text-center">
                  <Link href="/creators" className="text-gray-600 hover:text-alpineTeal px-3 py-2 text-sm font-bold uppercase underline-offset-4 hover:underline">
                    Hire&nbsp;Talent
                  </Link>
                </div>
              </div>

              {/* Right side buttons - Desktop */}
              <div className="hidden md:flex items-center space-x-4">
                <Link
                  onClick={() => trackClickSignupEvent(API, 'home-sign-in')}
                  href="/signin"
                  className="text-gray-600 hover:text-alpineTeal px-3 py-2 text-center text-sm font-bold uppercase underline-offset-4 hover:underline"
                >
                  Sign&nbsp;in
                </Link>
                <Link
                  onClick={() => trackClickSignupEvent(API, 'home-join-for-free')}
                  href="/signup"
                  className="text-white px-4 py-2 text-sm font-bold bg-alpineTeal uppercase text-center"
                >
                  Join for Free
                </Link>
              </div>

              {/* Mobile Menu Button */}
              <div className="md:hidden">
                <IconButton
                  aria-label="menu"
                  aria-controls="mobile-menu"
                  aria-haspopup="true"
                  onClick={handleMenuClick}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="mobile-menu"
                  anchorEl={anchorEl}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  open={isMenuOpen}
                  onClose={handleMenuClose}
                  PaperProps={{
                    sx: {
                      minWidth: '220px',
                    },
                  }}
                >
                  <MenuItem onClick={() => handleNavigation(creativesUrl)} className="uppercase font-bold">For Creatives</MenuItem>
                  <MenuItem onClick={() => handleNavigation(businessUrl)} className="uppercase font-bold">For Businesses</MenuItem>
                  <MenuItem onClick={() => handleNavigation('/jobs')} className="uppercase font-bold">Find Jobs</MenuItem>
                  <MenuItem onClick={() => handleNavigation('/creators')} className="uppercase font-bold">Hire Talent</MenuItem>
                  <Divider />
                  <MenuItem
                    onClick={() => handleNavigation('/signin')}
                    className="uppercase font-bold"
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      backgroundColor: 'white',
                      color: '#00A3A5',
                      margin: '16px 16px',
                      borderRadius: '4px',
                      border: '1px solid #00A3A5',
                      '&:hover': {
                        backgroundColor: '#F5F5F5',
                      },
                    }}
                  >
                    Sign in
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleNavigation('/signup')}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      backgroundColor: '#00A3A5',
                      color: 'white',
                      margin: '12px 16px',
                      borderRadius: '4px',
                      '&:hover': {
                        backgroundColor: '#008F91',
                      },
                    }}
                    className="uppercase font-bold"
                  >
                    Join for Free
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </div>
        </nav>

        {/* Hero Section */}
        <header className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6 md:py-16">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
            <div>
              <h1 className="text-3xl md:text-4xl lg:text-5xl font-extrabold mb-4 md:leading-[1.2] lg:leading-[1.2]">
                Showcase your work. Prove your impact. Unlock opportunities.
              </h1>
              <p className="text-gray-600 mb-8">
                Showcase your best work, track your content&apos;s impact,
                and get discovered for new opportunities. Gondola is the verified content
                attribution platform connecting creators, content, and career opportunities.
              </p>
              <div className="flex flex-wrap gap-4">
                <Link href="/signup" className="bg-alpineTeal text-white px-6 py-3 font-bold uppercase">
                  Join for Free
                </Link>
                <Link href={businessUrl} className="border border-alpineTeal text-alpineTeal px-6 py-3 font-bold uppercase">
                  For Businesses
                </Link>
              </div>
            </div>
            <div className="relative">
              <video
                loop
                id="hero-video"
                className="rounded-xl aspect-video"
                autoPlay
                muted
                playsInline
                width="100%"
              >
                <source
                  src="https://storage.googleapis.com/gondola-images/production/web-assets/home/benefit-21.mp4"
                  type="video/mp4"
                />
              </video>

            </div>
          </div>
        </header>

        {/* Features Section */}
        <section className="bg-gray-50 py-8">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-3xl md:text-4xl font-bold text-left mb-12">
              Join the top talent in the creative industry
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="text-left">
                <div className="mb-2 flex gap-2">
                  <PhotoCamera className="w-8 h-8 text-alpineTeal" />
                  <Videocam className="w-8 h-8 text-alpineTeal" />
                  <Laptop className="w-8 h-8 text-alpineTeal" />
                  <Brush className="w-8 h-8 text-alpineTeal" />
                </div>
                <h3 className="font-semibold text-lg mb-2">32,000 creatives</h3>
                <p className="text-gray-600">
                  Join a thriving community of photographers, videographers, designers,
                  and content creators&mdash;all credited for their work.
                </p>
              </div>
              <div className="text-left">
                <div className="mb-2 flex gap-2">
                  <Business className="w-8 h-8 text-alpineTeal" />
                  <SportsBasketballOutlined className="w-8 h-8 text-alpineTeal" />
                  <Star className="w-8 h-8 text-alpineTeal" />
                  <Theaters className="w-8 h-8 text-alpineTeal" />
                </div>
                <h3 className="font-semibold text-lg mb-2">28,000 brands</h3>
                <p className="text-gray-600">
                  From global enterprises to media companies to emerging startups,
                  brands trust Gondola to track and showcase their content&apos;s impact.
                </p>
              </div>
              <div className="text-left">
                <div className="mb-2 flex gap-2">
                  <Instagram className="w-8 h-8 text-alpineTeal" />
                  <X className="w-8 h-8 text-alpineTeal" />
                  <YouTube className="w-8 h-8 text-alpineTeal" />
                  <Facebook className="w-8 h-8 text-alpineTeal" />
                  <TikTokIcon className="mt-1 w-7 h-7 text-alpineTeal" />
                  <ThreadsIcon className="mt-1 w-7 h-7 text-alpineTeal" />
                </div>
                <h3 className="font-semibold text-lg mb-2">4+ million credits</h3>
                <p className="text-gray-600">
                  With more than 2 million credited posts, Gondola ensures content authenticity,
                  proper attribution, and real-time visibility into the creators behind the
                  world&apos;s most engaging digital media.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Recent Posts Section */}
        {trendingPosts && trendingPosts.length > 0 && (
          <section className="py-8">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <h2 className="text-3xl md:text-4xl font-bold mb-2">Recent trending posts on Gondola</h2>
              <Carousel
                posts={trendingPosts || []}
              />
            </div>
          </section>
        )}

        {/* Showcase Section */}
        <section className="bg-gray-50 py-12">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
              <div>
                <p className="text font-bold mb-2 uppercase text-alpineTeal">Portfolio</p>
                <h2 className="text-3xl font-extrabold mb-4">Showcase your work</h2>
                <p className="text-gray-600 mb-8">
                  Showcase your work and get the credit you deserve. Gondola lets you
                  build a portfolio of content&mdash;whether it&apos;s published on your account,
                  a client&apos;s, or an organization&apos;s. Simply add any content link,
                  tag your role, and credit collaborators, ensuring your contributions are
                  always recognized and easily shareable.
                </p>
              </div>
              <div className="bg-coral-500 rounded-lg p-8">
                <video
                  loop
                  id="showcase-video"
                  className="rounded-xl aspect-video"
                  autoPlay
                  muted
                  playsInline
                  width="100%"
                >
                  <source
                    src="https://storage.googleapis.com/gondola-images/production/web-assets/home/benefit-12.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
          </div>
        </section>

        {/* Prove Impact Section */}
        <section className="py-12">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
              <video
                loop
                id="data-video"
                className="rounded-xl aspect-video"
                autoPlay
                muted
                playsInline
                width="100%"
              >
                <source
                  src="https://storage.googleapis.com/gondola-images/production/web-assets/home/benefit-4.mp4"
                  type="video/mp4"
                />
              </video>
              <div>
                <p className="text font-bold mb-2 uppercase text-alpineTeal">Analytics</p>
                <h2 className="text-3xl font-extrabold mb-4 md:leading-[1.4]">
                  Prove your impact
                </h2>
                <p className="text-gray-600">
                  Gondola gives creators the data to prove their impact. Track how your content
                  performs across social platforms&mdash;whether it&apos;s posted on your account or
                  your client&apos;s. Easily access and share key metrics from any post or campaign,
                  helping you showcase your value, demonstrate results, and win more opportunities.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Hiring Section */}
        <section className="bg-gray-50 py-12">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
              <div>
                <p className="text font-bold mb-2 uppercase text-alpineTeal">Hiring</p>
                <h2 className="text-3xl font-extrabold mb-4 md:leading-[1.4]">Unlock opportunities</h2>
                <p className="text-gray-600 mb-8">
                  Gondola helps creatives turn their portfolio into career opportunities.
                  By showcasing verified work and building a strong, data-backed profile,
                  creatives can attract brands looking for trusted collaborators. Brands can
                  easily discover talent based on style, past partnerships, and
                  engagement&mdash;helping creators secure paid opportunities
                  and grow their careers.
                </p>
              </div>
              <div className="bg-coral-500 rounded-lg p-8">
                <video
                  loop
                  id="hiring-video"
                  className="rounded-xl aspect-video"
                  autoPlay
                  muted
                  playsInline
                  width="100%"
                >
                  <source
                    src="https://storage.googleapis.com/gondola-images/production/web-assets/home/benefit-3.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
          </div>
        </section>

        <JobsModule />

        {/* Testimonials Section */}
        <section className="py-8 bg-gray-50">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <p className="text font-bold mb-2 uppercase text-alpineTeal">Testimonials</p>
            <h2 className="text-3xl font-bold mb-8 text-left">What our customers say</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {testimonials.map((testimonial) => (
                <div key={testimonial.name} className="bg-white p-6 rounded-lg shadow-md">
                  <svg
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 41 33"
                    className="w-8 h-8 text-alpineTeal mb-4"
                  >
                    <path d="M32.6 14.16a8.19 8.19 0 015.83 3.1 10.44 10.44 0 012.18 6.73c0 2.55-.85 4.67-2.54 6.37-1.7 1.7-4 2.55-6.92 2.55-2.8 0-5.04-.85-6.74-2.55-1.7-1.82-2.54-4.55-2.54-8.19 0-3.76 1.57-7.95 4.73-12.56 3.15-4.73 6.43-7.89 9.83-9.46.48-.25 1.21.06 2.18.9.97.86 1.46 1.58 1.46 2.19-3.52 2.43-6 6.07-7.46 10.92zm-21.1 0a8.04 8.04 0 015.63 3.1 10.44 10.44 0 012.19 6.73c0 2.55-.85 4.67-2.55 6.37-1.58 1.7-3.82 2.55-6.73 2.55-2.8 0-5.04-.85-6.74-2.55C1.6 28.54.75 25.81.75 22.17c0-3.76 1.52-7.95 4.55-12.56C8.46 4.88 11.8 1.72 15.31.15c.49-.25 1.22.06 2.19.9.97.86 1.45 1.58 1.45 2.19-3.51 2.43-6 6.07-7.46 10.92z" />
                  </svg>
                  <p className="text-gray-600 mb-4">
                    &quot;
                    {testimonial.quote}
                    &quot;
                  </p>
                  <div className="flex items-center">
                    <div className="w-10 h-10 rounded-full bg-gray-200 mr-3">
                      <img src={testimonial.avatar} alt={testimonial.name} className="w-full h-full object-cover rounded-full" />
                    </div>
                    <div>
                      <p className="font-medium">{testimonial.name}</p>
                      <p className="text-sm text-gray-500">{testimonial.companyTitle}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Gondola for Business Section */}
        <header className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
            <div>
              <p className="text font-bold mb-2 uppercase text-alpineTeal">
                Gondola for Business
              </p>
              <h2 className="text-3xl md:text-4xl font-extrabold mb-4 md:leading-[1.4]">
                Hire top talent, track performance, and manage content&mdash;all in one place.
              </h2>
              <p className="text-gray-600 mb-8">
                Gondola for Business helps brands and agencies seamlessly hire creatives,
                tag and organize published content, and track talent performance&mdash;all in one
                intuitive platform.
              </p>
              <div className="flex flex-wrap gap-4">
                <Link href="/teams" className="bg-alpineTeal text-white px-6 py-3 font-bold uppercase">
                  Get Started
                </Link>
                <Link href={businessUrl} className="border border-alpineTeal text-alpineTeal px-6 py-3 font-bold uppercase">
                  Learn More
                </Link>
              </div>
            </div>
            <div className="relative">
              <video
                loop
                id="hero-video"
                className="rounded-xl aspect-video"
                autoPlay
                muted
                playsInline
                width="100%"
              >
                <source
                  src="https://storage.googleapis.com/gondola-images/production/web-assets/home/benefit-21.mp4"
                  type="video/mp4"
                />
              </video>

            </div>
          </div>
        </header>

        <CreatorHighlights />

        {/* Final CTA Section */}
        <section className="py-12 bg-[#F1F4F9]">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
            <h2 className="text-3xl font-extrabold mb-4 leading-relaxed">
              Credits, analytics, and portfolios for the entire creative industry.
            </h2>
            <div className="flex flex-wrap gap-4 justify-center mt-8">
              <Link href="/signup" className="text-sm bg-alpineTeal text-white px-6 py-3 font-bold uppercase">
                Join for Free
              </Link>
              <Link href={businessUrl} className="border bg-white border-alpineTeal text-sm text-alpineTeal px-6 py-3 font-bold uppercase">
                For Businesses
              </Link>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </>
  );
};

LoggedOutHomeComponent.getLayout = function getLayout(page: ReactElement) {
  return page;
};

export const LoggedOutHome = LoggedOutHomeComponent;
