import { notify } from 'lib/utils/honeybadgerUtils';
import { NextPageContext } from 'next';
import { map } from 'lodash';
import { BlogPost, CreatorLink } from 'lib/types';
import { LoggedOutHome, Home } from 'features/Home';
import { getUserIdFromContext } from 'server/api/services/auth';
import { serialize } from 'lib/helpers';
import { getLogger } from 'server/logger';
import { BlogService } from 'server/api/services/blog';
import { getRandomLinks } from 'components/ExploreModule/utils';
import { ExploreModuleLink } from 'components/ExploreModule';
import { CreatorLinkService } from 'server/api/services/creatorLinkService';
import { ReactElement } from 'react';
import { Layout } from 'components/Layout';

const logger = getLogger(module, 'pages/home');

interface Props {
  currentUserId: number;
  blogPosts?: BlogPost[];
  exploreLinks: ExploreModuleLink[];
}

export const getServerSideProps = async (context: NextPageContext) => {
  const currentUserId = await getUserIdFromContext(context);

  // This is logged out user. Nothing to fetch
  if (!currentUserId) {
    return {
      props: {
        currentUserId: null,
        blogPosts: [],
        exploreLinks: [],
      },
    };
  }

  try {
    const blogService = new BlogService();

    // Fetch blog posts and creator links in parallel
    const results = await Promise.allSettled([
      blogService.fetchLatestPosts(4),
      CreatorLinkService.getRandom(40, 6),
    ]);

    const [
      blogPosts, creatorLinks,
    ] = map(
      results, (result) => (result.status === 'fulfilled' ? result.value : []),
    );

    return {
      props: {
        currentUserId,
        blogPosts: serialize(blogPosts),
        exploreLinks: serialize(getRandomLinks(creatorLinks as CreatorLink[])),
      },
    };
  } catch (err: any) {
    logger.error(err);
    // On error we return an empty list so the home page still renders, but w/o trending posts
    const { query } = context;
    notify(err, 'Home', {
      query,
      message: 'Error fetching trending posts',
    });

    return {
      props: {
        currentUserId,
      },
    };
  }
};

const Index = ({
  currentUserId,
  blogPosts,
  exploreLinks,
}: Props) => {
  if (currentUserId) {
    return (
      <Home blogPosts={blogPosts} exploreLinks={exploreLinks} />
    );
  }
  return (
    <LoggedOutHome />
  );
};

Index.getLayout = function getLayout(page: ReactElement) {
  if (page.props.currentUserId) {
    return <Layout>{page}</Layout>;
  }
  return page;
};

// eslint-disable-next-line import/no-default-export
export default Index;
