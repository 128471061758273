import { ReactNode } from 'react';
import { Milestone } from 'lib/types';
import { milestoneAssetUrl, webAssetUrl } from 'lib/utils/imageUtils';
import { abbreviateNum } from 'lib/helpers';
import { convertZuluDate } from 'lib/utils/datetime';
import { ProfileUser } from 'lib/types/users';
import { MilestoneCardButton } from './MilestoneCardButton';

interface Props {
  type: string;
  milestones: Milestone[];
  profileUser: ProfileUser;
  total?: number;
}

const label: { [key: string]: ReactNode } = {
  posts: (
    <>
      <div className="text-xs">CREDITED</div>
      POSTS
    </>
  ),
  views: 'VIEWS',
  likes: 'LIKES',
  years: 'YEARS',
  days: (
    <>
      DAY
      <div className="text-xs">ACTIVE</div>
      <div className="text-xs">STREAK</div>
    </>
  ),
  weeks: (
    <>
      WEEK
      <div className="text-xs">ACTIVE</div>
      <div className="text-xs">STREAK</div>
    </>
  ),
};

export const MilestoneList = ({
  type,
  milestones,
  total,
  profileUser,
}: Props) => {
  if (milestones.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-wrap justify-between border p-4 my-4 rounded-lg bg-white shadow-sm">
      <div className="w-full md:w-1/6 mb-4 flex md:flex-col justify-center items-center text-center md:pr-4">
        <div className="w-16 p-2"><img src={webAssetUrl(`milestones/${type}-icon.png`)} alt={type} /></div>
        <div>
          {type === 'years' ? (
            <>
              <h2>Member since:</h2>
              <h3 className="text-xl font-semibold">{convertZuluDate(total as any)}</h3>
            </>
          ) : (
            <>
              <h3 className="text-3xl font-semibold">{abbreviateNum(total || 0, 1)}</h3>
              <h2 className="font-semibold">{label[type]}</h2>
            </>
          )}

        </div>
      </div>
      <div className="grid grid-cols-3 md:grid-cols-6 gap-6 w-full md:w-5/6">
        {milestones.map((ms: Milestone) => (
          <div className="col-span-1">
            <MilestoneCardButton
              milestone={ms}
              milestoneUserId={profileUser.id}
            >
              <img src={milestoneAssetUrl(ms)} alt={ms.name} className={!ms.achieved ? 'opacity-30 max-h-32' : 'max-h-32'} />
            </MilestoneCardButton>
          </div>
        ))}
      </div>
    </div>
  );
};
