import Dialog from '@mui/material/Dialog';
import { capitalize } from 'lodash';
import { useRouter } from 'next/router';

interface Props {
  onClose: () => void;
  name: string;
}

export const SubscriptionConfirmationModal = ({
  onClose, name,
}: Props) => {
  const router = useRouter();

  return (
    <Dialog open>
      <div className="relative p-6">
        <div className="mb-4 text-3xl text-teal font-bold">
          Welcome to Gondola
          {' '}
          {capitalize(name)}
          !
        </div>
        <div>
          We are so happy to have you.
        </div>
        <div className="text-xs mt-4">
          Please never leave us.
        </div>
        <div className="text-right">
          {name === 'starter' || name === 'teams' ? (
            <button type="button" className="btn-primary" onClick={() => router.push('/teams')} color="primary">
              Manage your team
            </button>
          ) : (
            <button type="button" className="btn-primary" onClick={onClose} color="primary">
              Ok
            </button>
          )}
        </div>
      </div>
    </Dialog>
  );
};
