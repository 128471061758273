import { useState, useEffect } from 'react';
import { useApi, useCurrentUser } from 'lib/contexts/ApplicationState';
import { PostCard } from 'components/PostCard';
import { Emoji } from 'elements/Emoji';
import {
  Stats, MyMetrics, Post, Milestone, MilestoneCounts,
} from 'lib/types';
import { toDateTime } from 'lib/utils/datetime';
import { MobileTooltip } from 'components/MobileTooltip';
import { Skeleton } from '@mui/material';
import { LoadingOverlay } from 'components/LoadingOverlay';
import { StatCard } from './StatCard';
import { StatWithDeltaCard } from './StatWithDeltaCard';
import { PostDeltas } from './PostDeltas';
import { MilestonesVert } from './MilestonesVert';
import { MilestonesHorz } from './MilestonesHorz';

// We only show the My Gondola module if net engagement is > 10
const meetsThreshold = (deltas?: Stats) => {
  if (!deltas) return false;

  if (deltas.totalLikes + deltas.totalComments + deltas.totalShares < 10) {
    return false;
  }
  return true;
};

export const MyGondola = () => {
  const API = useApi();
  const currentUser = useCurrentUser();

  const [myMetrics, setMyMetrics] = useState<MyMetrics>();
  const [myGondolaMilestones, setMyGondolaMilestones] = useState<
    {milestones: Milestone[]; milestoneCounts: MilestoneCounts}>();
  const [topPost, setTopPost] = useState<Post>();
  const [isLoading, setIsLoading] = useState(true);
  const [hasMyGondola, setHasMyGondola] = useState(false);
  const metrics = myMetrics?.lastWeekMetrics;
  const lastMonthMetrics = myMetrics?.lastMonthMetrics;

  useEffect(() => {
    const getMyGondolaMilestones = async (userId: number) => {
      const response = await API.getMyGondolaMilestones(userId);
      setMyGondolaMilestones(response);
      setIsLoading(false);
    };

    const getMyGondolaStats = async () => {
      const response = await API.getMyGondolaStats();
      setMyMetrics({
        lastWeekMetrics: response?.myGondolaStats?.lastWeekMetrics,
        lastMonthMetrics: response?.myGondolaStats?.lastMonthMetrics,
      });
      setTopPost(response?.topPost);

      if (meetsThreshold(response?.myGondolaStats?.lastWeekMetrics?.weeklyStats)
        && !!response?.topPost) {
        setHasMyGondola(true);
      }
    };

    if (currentUser) {
      getMyGondolaStats();
      getMyGondolaMilestones(currentUser.id);
    }
  }, [currentUser, API]);

  if (isLoading) {
    return (
      <div className="max-w-screen-xl mx-auto p-6">
        <div className="hidden sm:block"><Skeleton variant="rectangular" width="100%" height={200} /></div>
        <div className="sm:hidden"><Skeleton variant="rectangular" width="100%" height={400} /></div>
      </div>
    );
  }

  if (!hasMyGondola || !metrics) {
    return myGondolaMilestones?.milestones
      ? (
        <>
          <LoadingOverlay open={isLoading} />
          <div className="max-w-screen-xl mx-auto p-6">
            <div className="hidden sm:block">
              <MilestonesHorz
                milestones={myGondolaMilestones.milestones}
                milestoneCounts={myGondolaMilestones.milestoneCounts}
                showBeta
              />
            </div>
            <div className="sm:hidden">
              <MilestonesVert
                milestones={myGondolaMilestones.milestones}
                milestoneCounts={myGondolaMilestones.milestoneCounts}
                showBeta
              />
            </div>
          </div>
        </>
      ) : null;
  }

  const startDate = toDateTime(metrics.timePeriodStart).toFormat('MMM d');
  const endDate = toDateTime(metrics.timePeriodEnd).toFormat('MMM d');

  const betaTag = (
    <MobileTooltip
      title="We're testing this module to summarize your last week on Gondola. Only you can see your stats. Please let us know what you think or if you see anything wrong!"
    >
      <span className="cursor-default text-xs font-semibold inline-block py-1 px-2 rounded-full  bg-teal uppercase">
        Beta
      </span>
    </MobileTooltip>
  );

  return (
    <>
      <LoadingOverlay open={isLoading} />

      <div className="bg-hintgray mb-4">
        <div className="max-w-screen-xl mx-auto p-6">
          <h2 className="text-gray-700 text-2xl font-bold mb-4">
            My Gondola Snapshot
            {' '}
            {betaTag}
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-4 gap-4">
            {topPost
          && (
            <div className="col-span-1 p-2 bg-teal rounded-lg h-fit">
              <div className="text-white pb-2 text-center w-full text-lg font-semibold">
                Top post last week
                {' '}
                <Emoji emoji="😍" description="heart-eyes emoji" />
              </div>
              <div className="w-full p-2">
                <PostCard
                  post={topPost}
                  context="my-gondola"
                  includeMetrics={false}
                  includeCreditPreview
                />
                <PostDeltas
                  post={topPost}
                  decimals={0}
                />
              </div>
            </div>
          )}
            {myGondolaMilestones?.milestones && (
              <div className="col-span-1 flex h-full sm:hidden">
                <MilestonesVert
                  milestones={myGondolaMilestones.milestones}
                  milestoneCounts={myGondolaMilestones.milestoneCounts}
                />
              </div>
            )}
            <div className="col-span-1 sm:col-span-3 w-full">
              {myGondolaMilestones?.milestones && (
                <div className="mb-4 hidden sm:block">
                  <MilestonesHorz
                    milestones={myGondolaMilestones.milestones}
                    milestoneCounts={myGondolaMilestones.milestoneCounts}
                  />
                </div>
              )}
              <StatCard title="Total" subtitle={`Total as of ${endDate}`} stats={metrics.totalStats} />
              <StatWithDeltaCard
                title="Last Week"
                subtitle={`${startDate} - ${endDate}`}
                stats={metrics.weeklyStats}
                deltas={metrics.deltaStats}
                infoText="My Gondola stats are updated overnight. Check back every morning for the latest numbers."
              />
              {lastMonthMetrics && (
                <StatWithDeltaCard
                  title="Last Month"
                  subtitle={`${toDateTime(lastMonthMetrics.timePeriodStart).toFormat('MMM d')} - ${toDateTime(lastMonthMetrics.timePeriodEnd).toFormat('MMM d')}`}
                  stats={lastMonthMetrics.weeklyStats}
                  deltas={lastMonthMetrics.deltaStats}
                  infoText="Stats from the last 28 days. My Gondola stats are updated overnight. Check back every morning for the latest numbers."
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
