import { User } from 'lib/types/users';
import { A } from 'components/A';
import { CreatorListCard } from './CreatorListCard';

interface Props {
  title: string;
  creators: User[];
  moreUrl: string;
  moreTitle?: string;
  hideRank?: boolean;
  id: string;
}

export const CreatorList = ({
  title, creators, moreUrl, moreTitle, hideRank = false, id,
}: Props) => (
  <div className="mb-8" id={id}>
    <div className="flex justify-between mb-2 flex-wrap">
      <h3 className="font-bold text-lg" suppressHydrationWarning>{title}</h3>
      <div>
        <A
          href={moreUrl}
          className="text-lg"
        >
          {moreTitle || 'View all'}
          {' '}
          &raquo;
        </A>
      </div>
    </div>
    <div className="grid grid-flow-row grid-cols-2 md:grid-cols-6 gap-6">
      {creators.map((creator: User, i) => (
        <CreatorListCard
          key={creator.id}
          creator={creator}
          {...(!hideRank && { rank: i + 1 })}
        />
      ))}
    </div>
  </div>
);
